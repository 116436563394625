<template>
  <div id="carDataUploadDialog" ref="carDataUploadDialog" >
      <a-modal  v-model="visible"
        :title="title" 
        :okText="okText"
        :ok-button-props="okButtonProps"
        @ok="edgeModalHandleOk"
        @cancel="edgeModalHandleCancel"
        :getContainer="() => this.$refs.carDataUploadDialog"
      >

     <div v-loading="uploading" :element-loading-text="uploadingTxt">
              
              <a-row>
                <a-col :span="12"  style="padding:5px;margin:2px;border:1px solid #e8e8e8;">

                    <div style="width:100%;height:360px;overflow:auto;">
                        <div>
                            <!-- 人员所属 -->
                            {{$t('attendance.a3')}}
                        </div>
                        <a-tree
                          :show-line="true"
                          :show-icon="true"
                          :replace-fields="	{children:'children', title:'name', key:'id' }"
                          :tree-data="personGrList"
                          v-model="personGrId"
                          :selectedKeys.sync="personGrId"
                          :expandedKeys.sync="expandedKeys"
                          @select="personGrSelect"
                        >
                        </a-tree>
                    </div>
                </a-col>
                <a-col :span="11"  style="padding:5px;margin:2px;border:1px solid #e8e8e8;">
                  <div>
                      <!-- 选择导入文件 -->
                      {{$t('plateNumber.a109')}}
                  </div>
                 <div class="clearfix">
                    <a-upload :file-list="fileList" :multiple="false" :remove="handleRemove" :before-upload="beforeUpload">
                      <a-button> <a-icon type="upload" /> {{$t('edge.edge056')}} </a-button>
                    </a-upload>
                    
                  </div>
                </a-col>
              </a-row>
           
            
              <div  style="position: absolute;bottom: 0;left: 0;padding:10px;display: flex; justify-content: space-between;">
                  <a-button type="danger" icon="download" @click="downloadErrResult" :title="$t('energy.enedata531')" :disabled="!errData" style="margin-left: 5px;">  {{$t('energy.enedata530')}} </a-button>
              </div>
          </div> 
      </a-modal>
  </div>
</template>

<script>


import {uploadCarPlateData} from "../../api/passageway";
export default {
  name: "carDataUploadDialog",  
  props: {
    title: {
      type: String,
      default: '导入数据',
    },
    idInfo: {
      type: Object,
      default:{value:0,siteId:0,clientId:0,sitegrId:0,type:0},
    },
    personGrList:{
      type:Array,
      default:[],
    }
  },
  data() {
    return {
      loading : false,
      visible : false,
      upurl:"",
      headers: {
        authorization: 'authorization-text',
      },

      fileList:[],
      uploading:false,
      okText: this.$t('edge.edge058'),
      uploadingTxt:this.$t('edge.edge057'),
      okButtonProps:{ props: { disabled: true }, },
      errData:undefined,
      fileName:undefined,
      personGrId:[],
      expandedKeys:[],
    };
  }, 
  mounted(){
    console.log('mounted parent',this.$parent)
  },
  activated(){
    console.log('mounted parent',this.$parent)
  },
  watch:{
    "$parent.carDataUploadDialogVisible":{
      handler(val){
        console.log('carDataUploadDialogVisible>>',val);
        if(val){              
              // this.resetForm();
              this.fileList =[];
               this.expandedKeys=[];
               this.personGrId=[];
              this.uploading = false;
              this.expandedKeys.push(this.idInfo.siteId+"_0");
              this.personGrList.forEach(element => {
                  if(this.idInfo.siteId+"_0" === element.id){
                    this.personGrId.push(element.children[0].id);
                    this.expandedKeys.push(element.children[0].id);
                  }
                });
              this.visible = val;
              this.errData = undefined;
              this.fileName = undefined;
        }
      },
      deep:true,
      immediate:true,
    },
    "visible":{
      handler(val){
          if(!val){
              this.$parent.carDataUploadDialogVisible =  this.visible;
          }
      },
      deep:true,
      immediate:true,
    },
  },

  methods: {
    personGrSelect(selectedKeys, e){
        console.log('result',selectedKeys);
        const {selected, selectedNodes, node, event} = e;
        console.log('result',selected, selectedNodes, node, event);
        this.personGrId = selectedKeys;
        this.okButtonProps.props.disabled =  (this.fileList.length ===0 || this.personGrId.length===0);
    },
    downloadErrResult(){
        if(this.fileName && this.errData){
          let result = this.errData;
          let fileName = 'ERR-'+this.fileName;
          console.log('result',result);
          this.download(result,fileName);
        }
     },
     download(result,fileName){
        // 判断浏览器类型
        if ((navigator.userAgent.indexOf('compatible') > -1 &&
            navigator.userAgent.indexOf('MSIE') > -1) ||
            navigator.userAgent.indexOf('Edge') > -1) {
            //IE10或Edge浏览器
            const BOM = "\uFEFF";
            const csvData = new Blob([BOM+result], { type: "text/csv" });
            navigator.msSaveBlob(csvData, `${fileName}.csv`);
        } else {
            //非IE浏览器
            const csvContent = "data:text/csv;charset=utf-8,\uFEFF" + result;
            //使用a标签的download属性实现下载功能
            const link = document.createElement("a");
            link.href = encodeURI(csvContent);
            link.download = `${fileName}.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    },
    edgeModalHandleOk(){
      this.handleUpload();
    },
    edgeModalHandleCancel(){},
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      this.okButtonProps.props.disabled =  (this.fileList.length ===0 || this.personGrId.length===0);
      this.errlist = [];
    },
    beforeUpload(file) {
      let names = file.name.split(".");
      let len = names.length;
      if(names[len-1]!='csv'){
         this.$message.error(this.$t('energy.enedata543'));
         //  this.$message.error('文件格式错误，请选择CSV文件。');
         return false;
      }
      this.errlist = [];
      this.fileList = [file];
      this.okButtonProps.props.disabled =  (this.fileList.length ===0 || this.personGrId.length===0);
      return false;
    },
    handleUpload() {
      const { fileList } = this;
      const formData = new FormData();
      fileList.forEach(file => {
        formData.append('file', file);
      });
      
      let param =   null;
      // if(this.kind==='1'){
        
      // }

      param = {
          action:1,
          personGrId: this.personGrId[0],
          clientId: this.$store.getters.clientId,
          sitegrId: this.$store.getters.sitegrId,
          siteId:   this.$route.query.id,
          tenantId: 0
      };

      if(!param){
         this.$message.error(this.$t('energy.enedata567'));
         return ;
      }
      console.log("param",param);

      formData.append('param', JSON.stringify(param));
      this.uploading = true;
      this.okText = this.uploadingTxt;
      uploadCarPlateData(formData)
      .then((res)=>{
          console.log('UploadFile>>',res);
          if((res.errorCode=='00' || res.errorCode=='03') && res.data){
              this.$message.success(this.$t('edge.edge059'));
              this.visible = false;
          }else{
            
            if(res.data.errVales){
                this.errData = res.data.errVales;
            }else{
                this.errData = undefined;
            }

            if(res.errorCode=='09'){
                this.$message.error(res.msg);
                this.fileName = res.data.fileName;
            }else{
              
                this.$message.error(this.$t('plateNumber.a110'));//("车牌信息导入失败。");
                this.visible = false;
            }
            this.fileList = [];
          }
          this.okText = this.$t('edge.edge058');
          this.uploading = false;
      }).catch((err)=>{
          this.uploading = false;
          this.okText = this.$t('edge.edge058');
          this.$message.error(this.$t('edge.edge060'));
      });
      
    }, 
    
  },
  
  
};
</script>
<style scoped>

</style>