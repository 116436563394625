<!--门禁模块--车牌管理-->
<template>
  <div id="plateNumber" v-loading="loading" :element-loading-text="`${$t('plateNumber.a1')}....`">
    <!-- 头部 -->
    <div class="header">
      <!-- 页面标题 -->
      <my-headertitle>{{ $t("plateNumber.a2") }}</my-headertitle>
      <!-- 查询栏 -->
      <div class="query">
        <a-row :gutter="[16,16]">
          <!-- 管理代码 -->
          <a-col :span="3">
            <a-input v-model="managementCode" allow-clear :placeholder="$t('plateNumber.a3')"/>
          </a-col>
          <!-- 姓名 -->
          <a-col :span="3">
            <a-input v-model="name" allow-clear :placeholder="$t('plateNumber.a5')"/>
          </a-col>
          <!-- 手机号码 -->
          <a-col :span="3">
            <a-input v-model="phoneNumber" allow-clear :placeholder="$t('plateNumber.a7')"/>
          </a-col>
          <!-- 车牌号码 -->
          <a-col :span="3">
            <a-input v-model="plateNumber" allow-clear :placeholder="$t('plateNumber.a9')"/>
          </a-col>
          <!-- 车牌颜色 -->
          <a-col :span="3">
            <a-select v-model="carPlateColorIndex" style="width:100%" :title="$t('plateNumber.a11')" :placeholder="$t('plateNumber.a11')" v-if="brand!=21">
                <a-select-option v-for="(item, index) in carPlateColors" :key="index" :value="item.no" :title="item.name">
                  {{ item.name }}
                </a-select-option>
             </a-select>
             <a-select v-model="carPlateColorIndex" style="width:100%" :placeholder="$t('plateNumber.a11')" :title="$t('plateNumber.a11')"  v-else-if="brand===21">
                <a-select-option v-for="(item, index) in carPlateColors" :key="index" :value="item.no" :title="item.name">
                  {{ item.name }}
                </a-select-option>
             </a-select>
          </a-col>
          <!-- 认证模式 -->
          <a-col :span="3">
            <a-select v-model="authenticationModeIndex" style="width:100%" :placeholder="$t('plateNumber.a18')" :title="$t('plateNumber.a18')" v-if="brand!=21">
                <a-select-option v-for="(item, index) in carAuthModes" :key="index" :value="item.no" :title="item.text">
                  {{ item.text }}
                </a-select-option>
             </a-select>
             <a-select v-model="authenticationModeIndex" style="width:100%"  :placeholder="$t('plateNumber.a18')" :title="$t('plateNumber.a18')" v-else-if="brand===21">
                <a-select-option v-for="(item, index) in carAuthModes" :key="index" :value="item.value" :title="item.name">
                  {{ item.name }}
                </a-select-option>
             </a-select>
          </a-col>
          <!-- 开始日期 -->
          <a-col :span="11">
            <a-input-group compact>
              <!--  -->
              <div style=" width: 25%">
                <a-input style="pointer-events: none;text-align: center" :placeholder="$t('plateNumber.a27')" :disabled="true"/>
              </div>
              <!-- 初始日期 -->
              <div style=" width: 35%">
                <a-date-picker  show-time format="YYYY-MM-DD HH:mm:ss" @change="activationTimeStarChange" style=" width: 100%"/>
              </div>
              <!-- 过渡线 -->
              <div style=" width: 5%">
                <a-input style=" pointer-events: none; backgroundColor: #fff;text-align: center;padding:0px" placeholder="~"/>
              </div>
              <!-- 终止日期 -->
              <div style=" width: 35%">
                <a-date-picker  show-time format="YYYY-MM-DD HH:mm:ss" @change="activationTimeEndChange" style=" width: 100%"/>
              </div>
            </a-input-group>
          </a-col>
          <!-- 有效日期 -->
          <a-col :span="11">
            <a-input-group compact>
              <div style=" width: 25%">
                <a-input style=" pointer-events: none;text-align: center" :placeholder="$t('plateNumber.a28')" :disabled="true"/>
              </div>
              <!-- 初始日期 -->
              <div style=" width: 35%">
                <a-date-picker show-time format="YYYY-MM-DD HH:mm:ss"  @change="expirationTermStarChange" style=" width: 100%"/>
              </div>
              <!-- 过渡线 -->
              <div style=" width: 5%">
                <a-input style=" pointer-events: none; backgroundColor: #fff;text-align: center;padding:0px" placeholder="~"/>
              </div>
              <!-- 终止日期 -->
              <div style=" width: 35%">
                <a-date-picker show-time format="YYYY-MM-DD HH:mm:ss"  @change="expirationTermEndChange" style=" width: 100%"/>
              </div>
            </a-input-group>
          </a-col>
          <!-- 查询按钮 -->
          <a-col :span="2">
            <a-button type="primary" @click="searchPersonCarListData"> {{ $t("plateNumber.a29") }} </a-button>
          </a-col>
        </a-row>
      </div>
    </div>
    <!-- 表格区域 -->
    <div class="main" ref="plateNumber" v-table-size="tableSize">
      <a-table :columns="columns" :dataSource="tableData" :scroll="size" :pagination="pagination" :rowKey="(record) => record.id">
        <template slot="chaozuo" slot-scope="text, record">
          <div>
              <a-button  type="primary" icon="edit" shape="circle"  @click="() => personnelLicensePlate(record)" :title="$t('plateNumber.a33')"></a-button>
              <a-divider type="vertical" />
              <a-popconfirm  :title="$t('plateNumber.a86')" @confirm="() => deletePlateNumberRow(record)">
                  <a-button  shape="circle" icon="delete" type="danger" :title="$t('plateNumber.a31')"></a-button>
              </a-popconfirm>
          </div>
        </template>
      </a-table>
    </div> 
    <!-- 底部功能栏 -->
    <div class="footer">
      <div>
           <!-- 下载模板文件 -->
          <a-button type="primary" class="but" @click="downloadTemplateFile">{{   $t("plateNumber.a89") }}</a-button>
          <!-- 上传车牌信息 -->
          <a-button type="primary" class="but" @click="openUploadModel">{{   $t("plateNumber.a90") }}</a-button>
          <!-- 导出车牌信息 -->
          <a-button type="primary" @click="downloadCarPlateNumber">{{   $t("plateNumber.a91") }}</a-button>
      </div>
      <div style="text-align:right;">
        <!-- <a-button type="primary" @click="editCarPlateNumber">{{   $t("plateNumber.a36") }}</a-button> -->
        <a-button type="primary" @click="personnelLicensePlate">{{   $t("plateNumber.a36") }}</a-button>
      </div>
    </div>

    <!-- 车牌管理/添加车牌 -->
    <a-modal
      :title="establish ? $t('plateNumber.a33') : $t('plateNumber.a36')"
      centered
      :width="800"
      :visible="plateNumberVisible"
      :getContainer="() => this.$refs.plateNumber"
    >
      <template slot="footer">
          <a-button key="submit" type="primary" @click="plateNumberOk">
            {{ $t("plateNumber.a38") }}
          </a-button>
          <a-button key="back" @click="plateNumberCancel">
            {{ $t("plateNumber.a39") }}
          </a-button>
      </template>
      <div class="form" v-loading="plateNumberloading" :element-loading-text="`${$t('plateNumber.a1')}....`" >
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-row>
            <a-col :span="12">
              <!-- <a-form-model-item style="margin-bottom: 10px"  :label="$t('plateNumber.a41')" v-if="establish">
                <a-input disabled :value="form.managementCode" />
              </a-form-model-item>
              <a-form-model-item  style="margin-bottom: 10px" :label="$t('plateNumber.a5')"  v-if="establish"  prop="name">
                  <a-input disabled :value="form.name" />
              </a-form-model-item> -->
              <a-form-model-item style="margin-bottom: 10px" :label="$t('plateNumber.a50')"   prop="name" >
                <a-input allow-clear v-model="form.name" />
              </a-form-model-item>

              <a-form-model-item  style="margin-bottom: 10px" :label="$t('plateNumber.a7')"  prop="mobilePhone" >
                  <a-input allow-clear v-model="form.mobilePhone" />
              </a-form-model-item>
             
              <a-form-model-item style="margin-bottom: 10px" :label="$t('plateNumber.a9')" prop="carPlateNO" >
                <a-input allow-clear v-model="form.carPlateNO" @change="carPlateNOChange"/>
              </a-form-model-item>
              <!-- 通行区域 -->
              <a-form-model-item :label="$t('plateNumber.a92')" style="margin-bottom: 10px" v-if="brand===21" prop="area">
                <a-select v-model="form.area" placeholder="please select your zone" mode="multiple">
                  <a-select-option :value="item.no" v-for="(item,index) in carParkAreas" :key="index"> 
                    {{item.name}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item style="margin-bottom: 10px"   :label="$t('plateNumber.a11')"  prop="carPlateColor" >
                <a-select v-model="form.carPlateColor" :title="$t('plateNumber.a11')" v-if="brand!=21">
                    <a-select-option v-for="(item, index) in carPlateColors1" :key="index" :value="item.no" :title="item.text">
                      {{ item.text }}
                    </a-select-option>
                </a-select>
                <a-select v-model="form.carPlateColor"  :placeholder="$t('plateNumber.a11')" :title="$t('plateNumber.a11')" v-else-if="brand===21">
                    <a-select-option v-for="(item, index) in carPlateColors1" :key="index" :value="item.no" :title="item.name">
                      {{ item.name }}
                    </a-select-option>
                </a-select>
              </a-form-model-item>
               
              <a-form-model-item style="margin-bottom: 10px" :label="$t('plateNumber.a51')" >
                <a-input allow-clear v-model="form.carColor" />
              </a-form-model-item>
              <!-- 车主性别 0：女 1：男 -->
             <a-form-model-item :label="$t('plateNumber.a93')" style="margin-bottom: 10px">
                <a-radio-group v-model="form.personGender">
                    <a-radio :value="0">  {{$t('plateNumber.a94')}} </a-radio>
                    <a-radio :value="1">  {{$t('plateNumber.a95')}} </a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              
              <a-form-model-item style="margin-bottom: 10px" :label="$t('plateNumber.a53')" prop="authenticationMode" >
                <a-select v-model="form.authenticationMode" :title="$t('plateNumber.a53')" v-if="brand!==21">
                  <a-select-option v-for="(item, index) in carAuthModes1" :key="index" :value="item.no" :title="item.text">
                    {{ item.text }}
                  </a-select-option>
                </a-select>

                 <a-select v-model="form.authenticationMode" :placeholder="$t('plateNumber.a53')" :title="$t('plateNumber.a53')" v-else-if="brand===21">
                    <a-select-option v-for="(item, index) in carAuthModes1" :key="index" :value="item.value" :title="item.name">
                      {{ item.name }}
                    </a-select-option>
                </a-select>

              </a-form-model-item>

              <a-form-model-item style="margin-bottom: 10px" :label="$t('plateNumber.a27')" prop="activationTime">
                  <a-date-picker v-model="form.activationTime" style="width: 100%" />
              </a-form-model-item>
              <a-form-model-item style="margin-bottom: 10px" :label="$t('plateNumber.a28')" prop="expirationTerm"  >
                  <a-date-picker v-model="form.expirationTerm" style="width: 100%" />
              </a-form-model-item>

              <!-- 组织部门 请选择组织部门-->
              <a-form-model-item :label="$t('plateNumber.a96')"  style="margin-bottom: 10px"  v-if="brand===21" prop="deptId">
                <a-tree-select
                  v-model="form.deptId"
                  :tree-data="carParkDepts"
                  :replaceFields="{children:'children', title:'name', key:'no', value: 'no' }  "
                  style="width: 100%"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  :placeholder="$t('plateNumber.a97')"
                  allow-clear
                  tree-default-expand-all
                /> 
              </a-form-model-item>
               
              <a-form-model-item style="margin-bottom: 10px" :label="$t('plateNumber.a45')" >
                  <a-input allow-clear v-model="form.email" />
              </a-form-model-item>
              <a-form-model-item style="margin-bottom: 10px" :label="$t('plateNumber.a52')" >
                <a-input allow-clear v-model="form.carBrand" />
              </a-form-model-item>

               <!-- 第三方系统 同步数据 -->
              <a-form-model-item :label="$t('plateNumber.a98')" style="margin-bottom: 10px">
                  <a-checkbox v-model="form.push">   {{$t('plateNumber.a99')}} </a-checkbox>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </a-modal>

      <!-- :personGrs="brand" :sites="brand"  -->
    <person-plate :brand="brand" :areas="carParkAreas" :idInfo="idInfo" :sites="sites"  :title="$t('plateNumber.a2')"
        :carParkDepts="carParkDepts" :carAuthModes="carAuthModes1" :carPlateColors="carPlateColors1" ></person-plate>
    <upload-dialog :idInfo="idInfo" :personGrList="personGrList" :title="$t('plateNumber.a111')"></upload-dialog>
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import headerTitle from "../../components/Title/headerTitle";
import personPlate from "./personPlateNumberModel";
import uploadDialog from "./carDataUploadDialog";
import { getPlateNumberParams, saveCarPlateNumber, deleteCarPlateNumber, getPersonCarData, getPersonCarListData } from "../../api/door";
import { downloadPersonCarInfoList} from "../../api/passageway";
import { getPersonList} from "../../api/info";
import { downloadUrl } from "../../utils/utils";
import moment from "moment";
const express = /^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[a-zA-Z](([ABCDEFGHK]((?![IO])[a-zA-Z0-9](?![IO]))[0-9]{4})|([0-9]{5}[DF]))|[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1})$/;
const expressMobile = /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/;

export default {
  name: "plateNumber",
  data() {
    //车牌校验方法
    let validateCarPlateNumber = async (rule, value) => {
      if (value === '') {
        return Promise.reject(this.$t("plateNumber.a57"));
      } else if (!express.test(value)) {
        return Promise.reject(this.$t("plateNumber.a68"));
      } else {
        return Promise.resolve();
      }
    };
    //手机号码校验方法
    let validateMobile = async (rule, value) => {
      if (value === '') {
        return Promise.reject(this.$t("plateNumber.a56"));
      } else if (!expressMobile.test(value)) {
        return Promise.reject(this.$t("plateNumber.a67"));
      } else {
        return Promise.resolve();
      }
    };
    return {
      managementCode: "",
      name: "",
      phoneNumber: "",
      plateNumber: "",
      carPlateColorIndex: undefined,
      authenticationModeIndex: undefined,
      time: "",
      timeSelect: null,
      disabled: true,
      tableData: [],
      loading: false,
      size: { y: 240 },
      personVisible: false,
      personLoading: false,
      grouping: "",
      count: 0,
      personCarBeanList: [],
      plateNumberVisible: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      establish: false,
      plateNumberloading: false,
      rules: {
        mobilePhone: [
          {
            required: true,
            validator: validateMobile,
            trigger: "change",
          },
        ],
        carPlateNO: [
          {
            required: true,
            validator: validateCarPlateNumber,
            trigger: "change",
          }
        ],
        authenticationMode: [
          {
            required: true,
            message: () => {
              return this.$t("plateNumber.a69");
            },
            trigger: "change",
          },
        ],
        carPlateColor: [
          {
            required: true,
            message: () => {
              return this.$t("plateNumber.a88");
            },
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: () => {
              return this.$t("plateNumber.a87");
            },
            trigger: "change",
          },
        ],
        activationTime: [
          {
            required: true,
            message: () => {
              return this.$t("plateNumber.a58");
            },
            trigger: "change",
          },
        ],
        expirationTerm: [
          {
            required: true,
            message: () => {
              return this.$t("plateNumber.a58");
            },
            trigger: "change",
          },
        ],
        deptId: [{ required: true, message: this.$t('plateNumber.a97'), trigger: 'change' }],
        area:[{
            type: 'array',
            required: true,
            message: this.$t('plateNumber.a100'),//'请选择所属',
            trigger: 'change',
              },
        ],
      },
      form: {
        name: "",
        activationTime: null,
        expirationTerm: null,
        authenticationMode: undefined,
        carPlateColor: undefined,
        carBrand: "",
        carColor: "白色",
        carPlateNO: "",
        managementCode: "",
        mobilePhone: "",
        email: "",
        personGender:1,
        area:[],
        deptId:'',
        push:true,
      },
      pagination: {
        total: 0, //数据总数
        pageSize: 40, //每页中显示10条数据
        showTotal: (total) => ` ${total} ${this.$t("plateNumber.a59")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
      columns: [
        {
          title: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          width: 60                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
        },
        {
          title: this.$t("plateNumber.a3"),
          dataIndex: "managementCode"
        },
        {
          title: this.$t("plateNumber.a5"),
          dataIndex: "name"
        },
        {
          title: this.$t("plateNumber.a7"),
          dataIndex: "mobilePhone"
        },
        {
          title: this.$t("plateNumber.a9"),
          dataIndex: "carPlateNO"
        },
        {
          title: this.$t("plateNumber.a11"),
          dataIndex: "carPlateColor",
          customRender: (text, record, index) =>
            `${
              (
                this.$t("plateNumber.a"+(parseInt(record.carPlateColor)+12))
              )
            }`,
        },
        {
          title: this.$t("plateNumber.a18"),
          dataIndex: "authenticationMode",
          customRender: (text, record, index) =>
            `${
              (
                this.getAuthModeText(record)
              )
            }`
        },
        {
          title: this.$t("plateNumber.a27"),
          dataIndex: "activationTime"
        },
        {
          title: this.$t("plateNumber.a28"),
          dataIndex: "expirationTerm"
        },
        {
          title: this.$t("plateNumber.a60"),
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          width: 120,
          align:'center'   
          
        }
      ],
      palteNumberColumns: [
        {
          title: this.$t("plateNumber.a9"),
          dataIndex: "carPlateNO",
          scopedSlots: { customRender: "carPlateNO" },
          ellipsis: true,
        },
        {
          title: this.$t("plateNumber.a11"),
          dataIndex: "carPlateColor",
          scopedSlots: { customRender: "carPlateColor" },
          ellipsis: true,
        },
        {
          title: this.$t("plateNumber.a51"),
          dataIndex: "carColor",
          scopedSlots: { customRender: "carColor" },
          ellipsis: true,
        },
        {
          title: this.$t("plateNumber.a52"),
          dataIndex: "carBrand",
          scopedSlots: { customRender: "carBrand" },
          ellipsis: true,
        },
        {
          title: this.$t("plateNumber.a53"),
          dataIndex: "authenticationMode",
          scopedSlots: { customRender: "authenticationMode" },
          ellipsis: true,
        },
        {
          title: this.$t("plateNumber.a61"),
          dataIndex: "activationTime",
          scopedSlots: { customRender: "activationTime" },
          ellipsis: true,
        },
        {
          title: this.$t("plateNumber.a64"),
          dataIndex: "expirationTerm",
          scopedSlots: { customRender: "expirationTerm" },
          ellipsis: true,
        },
        {
          title: this.$t("plateNumber.a60"),
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          width: 120,
          // fixed: "right",
        },
      ],
      carAuthModes:[{no:"",text:this.$t("plateNumber.a26"),value:"",name:this.$t("plateNumber.a26")}],
      carPlateColors:[{no:"",text:this.$t("plateNumber.a26"),value:"",name:this.$t("plateNumber.a26")}],
      brand:0,
      carAuthModes1:[],
      carPlateColors1:[],
      carParkAreas:[],
      carParkDepts:[],
      carParams:undefined,
      activationTimeStar:undefined,
      activationTimeEnd:undefined,
      expirationTermStar:undefined,
      expirationTermEnd:undefined,
      // expirationTermStar:new moment().startOf("date"),
      // expirationTermEnd:new moment().endOf("date"),
      dialogVisible:false,
      sites:[],
      personGrs:[],
      idInfo:{
          type:0,
          value:0, 
          clientId: this.$store.getters.clientId,
          sitegrId: this.$store.getters.sitegrId,
          siteId: this.$route.query.id,
      },
      carDataUploadDialogVisible:false,
      personGrList:[],
    };
  },
  watch: {
    // 时间模式选择后时间选择框的处理方式
    time: {
      handler: function (newValue, oldValue) {
        if (newValue == "") {
          this.timeSelect = null;
          this.disabled = true;
        } else {
          this.timeSelect = moment();
          this.disabled = false;
        }
      },
    }
  },
  computed: {
    activationTime() {
      const timeSelect = this.timeSelect;
      const time = this.time;
      switch (time) {
        case "":
          return "";
          break;
        case "1":
          return timeSelect.format("YYYY-MM-DD");
          break;
        case "2":
          return "";
        default:
          return "";
          break;
      }
    },
    expirationTerm() {
      const timeSelect = this.timeSelect;
      const time = this.time;
      switch (time) {
        case "":
          return "";
          break;
        case "1":
          return "";
          break;
        case "2":
          return timeSelect.format("YYYY-MM-DD");
        default:
          return "";
          break;
      }
    },
  },
  activated(){
    this.initPage();
    this.getPersonGrList();
  },
  methods: {
    moment,
    // 表格自适应方法
    tableSize() {
      const indexDom = document.getElementById("plateNumber")
      if (indexDom !== null) {
        const height = indexDom.getElementsByClassName("main")[0].clientHeight
        const title = indexDom.getElementsByClassName("ant-table-thead")[0].clientHeight
        const tableHeight = height - 64 - title
        this.size.y = tableHeight
      }
    },

    // 人员车牌管理
    personnelLicensePlate(item) {
      if(item && item.id){
          const {id,parentId} = item;
          if(parentId>0){
              this.idInfo.type = 0;
              this.idInfo.value = parentId;
          }else{
              this.idInfo.value = id;
              this.idInfo.type = 1;
          }
      }else{
          this.idInfo.value = 0;
          this.idInfo.type = 0;
      }
      this.dialogVisible = true;
    },

    // 车牌管理设置按钮
    plateNumberOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.plateNumberloading = true;

          let car = this.form;
         
          car.activationTime = moment(car.activationTime).format("YYYY-MM-DD" );
          car.expirationTerm = moment(car.expirationTerm).format("YYYY-MM-DD" );
          let param = {
              action: 0,
              clientId: this.$store.getters.clientId,
              sitegrId: this.$store.getters.sitegrId,
              siteId: this.$route.query.id,
              car:car,
          };
          console.log('saveCarPlateNumber param',param);
          saveCarPlateNumber(param)
            .then((res) => {
              console.log('saveCarPlateNumber res',res);
              this.plateNumberloading = false;
              const personCarId = res.data.personCarId;
              const { errorCode, msg } = res;
              const { data } = res;
              if (errorCode == "00") {
                this.$message.success(msg);
                
              } else {
                this.$message.error(msg);
              }
            })
            .catch((err) => {
              console.log(err);
              this.plateNumberloading = false;
            });
        }
      });
    },

    // 车牌管理关闭按钮
    plateNumberCancel() {
      this.plateNumberVisible = false;
    },

    // 车牌新增修改的按钮方法
    editCarPlateNumber(value) {
      console.log(value);
      if (!value || value.id == undefined) {
        const data = {
          name: "",
          activationTime: new moment(),
          expirationTerm:  new moment(),
          authenticationMode: undefined,
          carPlateColor: undefined,
          carBrand: "",
          carColor: "",
          carPlateNO: "",
          managementCode: "",
          mobilePhone: "",
          email: "",
          personGender:1,
          area:[],
          deptId:'',
          push:true,
        };
        
        this.establish = false;
        this.form = data;
        this.plateNumberVisible = true;
      } else {
        getPersonCarData(value.id)
        .then((res) => {
          console.log('GetPersonCarData res',res)
        
          const { data ,errorCode,msg} = res;
          if(errorCode==='00'){
              this.form = data;
              this.form.activationTime = moment(data.activationTime);
              this.form.expirationTerm = moment(data.expirationTerm);
              this.form.push=true;

              this.establish = true;
              this.plateNumberVisible = true;
               console.log(this.carAuthModes1);
          }
        })
        .catch((err) => {
          console.log(err);
        });
        
      }
      
    },

    // 车牌删除接口---按钮方法
    deletePlateNumberRow(value) {
      // console.log(value);
      if(value && value.id){
          let ids = [];
          ids.push(value.id);
          this.deletePlateNumber(ids);
      }
    },

    // 车牌删除接口
    deletePlateNumber(ids) {
        const data = {
          action: 0,
          clientId: this.$store.getters.clientId,
          sitegrId: this.$store.getters.sitegrId,
          siteId: this.$route.query.id,
          ids: ids,
        };
        this.loading = true;
        deleteCarPlateNumber(data)
        .then((res) => {
            this.loading = false;
            const { errorCode, msg } = res;
            if (errorCode == "00" || errorCode == "05") {
                this.$message.success(msg);
                
                this.searchPersonCarListData();
            } else {
                this.$message.error(msg);
            }
            
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    //批量删除车牌
    batchDeletePlateNumber(ids) {
        // console.log(value);
        if(ids && ids.length>0){
            this.$confirm({
                title: this.$t('plateNumber.a86'),// '确认要删除车牌吗？',//
                centered: true,
                onOk: () => {
                    this.deletePlateNumber(ids);
                },
            });   
        }
    },

    //查询车牌信息
    searchPersonCarListData(){
      let param = {
          action: 0,
          clientId: this.$store.getters.clientId,
          sitegrId: this.$store.getters.sitegrId,
          siteId: this.$route.query.id,
          managementCode:this.managementCode,
          name:this.name,
          mobilePhone:this.phoneNumber,
          carPlateNO:this.plateNumber,
          carPlateColor:this.carPlateColorIndex,
          authenticationMode:this.authenticationModeIndex,
          activationTimeStar:this.activationTimeStar?this.activationTimeStar:'',
          activationTimeEnd:this.activationTimeEnd?this.activationTimeEnd:'',
          expirationTermStar:this.expirationTermStar?this.expirationTermStar:'',
          expirationTermEnd:this.expirationTermEnd?this.expirationTermEnd:'',
      }
      console.log('searchPersonCarListData param',param);
      this.loading = true;
      getPersonCarListData(param)
      .then((res) => {
        console.log('searchPersonCarListData res',res)
      
        const { data ,errorCode,msg} = res;
        if(errorCode==='00' && data.data){
            this.tableData = data.data;
        }else{
            this.tableData = [];
        }
       
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
      });
    },

    //页面数据初始化
    initPage(){
      this.loading = true;
      let siteId = this.$route.query.id;
      getPlateNumberParams(siteId)
      .then((res) => {
        console.log(res)
        const { data, data: { brand, carAuthModeList, clients, plateColorList, plateMealList } } = res
        this.carParams = data
        this.brand = brand
        if(this.brand == 21){
            this.carParkAreas =  res.data.areaList;
            this.carParkDepts = [];
            if(res.data.deptTree){
              this.carParkDepts.push(res.data.deptTree);
            }
            this.carAuthModes = [...this.carAuthModes,...plateMealList] 
            this.carAuthModes1 = [...plateMealList] 
            this.carPlateColors = [...this.carPlateColors,...plateColorList]
            this.carPlateColors1 = [...plateColorList]
        }else{
            this.carAuthModes = [...this.carAuthModes,...carAuthModeList] 
            this.carAuthModes1 = [...carAuthModeList]
            this.carPlateColors = [...this.carPlateColors,...plateColorList]
            this.carPlateColors1 = [...plateColorList]
        }
        this.sites = res.data.clients;
        this.loading = false;
      })
      .catch((err) => {
        this.personLoading = false;
        this.loading = false;
        console.log(err);
      });
    },
    //获取认证模式文本数据
    getAuthModeText(record){
      if(this.brand===21){
        let name = '';
        this.carAuthModes.forEach(element => {
            if(record.authenticationMode===element.value){
              name = element.name;
            }
        });
        return name;
      }else{
        console.log(record.authenticationMode + 70,"plateNumber.a"+(parseInt(record.authenticationMode)+70))
        return this.$t(`plateNumber.a${(parseInt(record.authenticationMode)+70)}`)
      }
    },
    activationTimeStarChange(date, dateString) {
      console.log(date, dateString);
      // this.activationTimeStar = date.startOf('date');
      this.activationTimeStar = dateString;
    },
    activationTimeEndChange(date, dateString) {
      console.log(date, dateString);
      // this.activationEndStar = date.endOf('date');
      this.activationTimeEnd = dateString;
    },
    expirationTermStarChange(date, dateString) {
      console.log(date, dateString);
      // this.expirationTermStar = date.startOf('date');
      this.expirationTermStar = dateString;
    },
    expirationTermEndChange(date, dateString) {
      console.log(date, dateString);
      // this.expirationTermEnd = date.endOf('date');
      this.expirationTermEnd = dateString;
    },

    //车牌改变，判断车牌颜色方法
    personCarPlateNOChange(e){
      let value = e.target.value;
      console.log(e,value.length);
      // let key = record.key;
      // console.log(key);
      let bol = express.test(value);
      if(bol){
        // if(value.length==7){
        //   record.carPlateColor = '3';
        // }else if(value.length==8){
        //   record.carPlateColor = '5';
        // }
      }
    },

    //车牌改变，判断车牌颜色方法
    carPlateNOChange(e){
      let value = e.target.value;
      console.log(value,value.length);
      let bol = express.test(value);
      if(bol){
        if(value.length==7){
          this.form.carPlateColor = '3';
        }else if(value.length==8){
          this.form.carPlateColor = '5';
        }
      }
    },

    downloadTemplateFile(){
        this.loading = true;
        let param = {
            action: 9,
            clientId: this.$store.getters.clientId,
            sitegrId: this.$store.getters.sitegrId,
            siteId: this.$route.query.id,
        };
        downloadPersonCarInfoList(param)
        .then((res) => {
            console.log('downloadTemplateFile RESULT',res);
            this.loading = false;
            let name  = (this.$store.getters.siteName?this.$store.getters.siteName+'-':'');
            name+=this.$t('plateNumber.a2');
            name+="-";
            name+=this.$t('plateNumber.a101');
            name+="-";
            name+=moment().format('YYYYMMDDHHmmssSSS')+'.zip';
            console.log("downloadTemplateFile Result>>",name);
            downloadUrl(res,name);
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
      
    },

    openUploadModel(){
      this.carDataUploadDialogVisible = true;
    },

    downloadCarPlateNumber(){
        this.loading = true;
        let param = {
            action: 0,
            clientId: this.$store.getters.clientId,
            sitegrId: this.$store.getters.sitegrId,
            siteId: this.$route.query.id,
            managementCode:this.managementCode,
            name:this.name,
            mobilePhone:this.phoneNumber,
            carPlateNO:this.plateNumber,
            carPlateColor:this.carPlateColorIndex,
            authenticationMode:this.authenticationModeIndex,
            activationTimeStar:this.activationTimeStar?this.activationTimeStar:'',
            activationTimeEnd:this.activationTimeEnd?this.activationTimeEnd:'',
            expirationTermStar:this.expirationTermStar?this.expirationTermStar:'',
            expirationTermEnd:this.expirationTermEnd?this.expirationTermEnd:'',
        };
        downloadPersonCarInfoList(param)
        .then((res) => {
            console.log('downloadTemplateFile RESULT',res);
            this.loading = false;
            let name  = (this.$store.getters.siteName?this.$store.getters.siteName+'-':'');
            name+=this.$t('plateNumber.a2');
            name+="-";
            name+=moment().format('YYYYMMDDHHmmssSSS')+'.zip';
            console.log("downloadTemplateFile Result>>",name);
            downloadUrl(res,name);
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
      
    },

     // 获取管理所属列表
    getPersonGrList() {
      // this.loading = true;
      const data = {
        siteGrId: this.$store.getters.sitegrId,
      };
      getPersonList(data)
        .then((res) => {
          // this.loading = false;
          const { data } = res;
          this.personGrList = data;
          this.personGrList.forEach(element => {
            element.selectable = false;
          });
          // console.log(res);
        })
        .catch((err) => {
          // this.loading = false;
          // console.log(err);
        });
    },
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
    "person-plate":personPlate,
    "upload-dialog":uploadDialog,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#plateNumber {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.header {
  min-width: 850px;
  height: 161px;
  padding: 0 20px;
  overflow: hidden;
}
.query{
  margin: 15px 0px;
}
.main {
  height: calc(100% - 213px);
  width: 100%;
  min-width: 670px;
  padding: 0px 20px;
  overflow: hidden;
}
.footer {
  display: flex;
  justify-content: space-between;
  height: 52px;
  padding: 10px 20px;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
}
.but {
  margin-right: 15px;
}
</style>