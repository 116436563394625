<!-- 车牌管理 -->
<template>
    <div id="personPlateNumberModel" ref="personPlateNumberModel">
        <a-modal v-model="visible" :title="title" centered :width="1200" @ok="onSubmit" :destroyOnClose="true" v-drag>
            <!-- 内容区域 -->
            <div v-loading="loading" :element-loading-text="$t('alarm.a0')" class="main">
                <a-form-model ref="ruleForm" :model="form" :rules="rules">
                    <!-- 人员类型和所属站点 -->
                    <div class="header">
                        <div>
                            <!-- 人员类型： 系统人员  普通人员-->
                            <span>{{$t('plateNumber.a102')}}：</span>
                            <a-radio-group v-model="form.type" @change="personTypeChange">
                                <a-radio :value="0" :disabled="idInfo.value>0">  {{$t('plateNumber.a103')}} </a-radio>
                                <a-radio :value="1" :disabled="idInfo.value>0">  {{$t('plateNumber.a104')}} </a-radio>
                            </a-radio-group>
                        </div>
                        <div v-if="form.type == 0">
                             <!-- 所属站点 请选择所属站点-->
                            <span>{{$t('plateNumber.a46')}}：</span>
                            <a-tree-select style="width:180px;"  :placeholder="$t('plateNumber.a105')"
                                v-model="form.person.personSiteId" :tree-data="sites"
                                :replaceFields="{children:'children', title:'name', key:'key', value: 'key' }  " 
                                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                @change="siteSelectChange"
                                allow-clear  
                                tree-default-expand-all
                                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                            /> 
                        </div>
                    </div>
                    <!-- 人员信息 -->
                    <div class="personnel-info">
                        <div class="plate-title">
                             <!-- 人员信息 -->
                             {{$t('sidebar.c3')}}
                        </div>
                        <div class="plate-content">
                            <a-row :gutter="[16,16]">
                               <!-- 组织部门 请选择组织部门-->
                                <a-col :span="4" v-if="form.type == 0">
                                   <a-form-model-item :label="$t('plateNumber.a96')"  >
                                        <a-tree-select style="width:100%;"  :placeholder="$t('plateNumber.a97')"
                                            v-model="form.person.personGrId" :tree-data="personGrs"
                                            :replaceFields="{children:'children', title:'name', key:'id', value: 'id' }  " 
                                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                            @change="personGrSelectChange"
                                            allow-clear  tree-default-expand-all
                                        /> 
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="4" v-if="form.type == 0">
                                     <!-- 车主姓名 请选择人员信息-->
                                    <a-form-model-item :label="$t('plateNumber.a42')" prop="person.id" :rules="rules.id">
                                        <a-tree-select style="width:100%;"  :placeholder="$t('plateNumber.a106')"
                                            v-model="form.person.id" :tree-data="persons"
                                            :replaceFields="{children:'children', title:'name', key:'id', value: 'id' }  " 
                                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                            @change="personSelectChange"
                                            allow-clear  tree-default-expand-all
                                        /> 
                                    </a-form-model-item>
                                </a-col>
                                <!-- 车主姓名 -->
                                <a-col :span="4" v-if="form.type == 1">
                                    <a-form-model-item :label="$t('plateNumber.a42')" prop="person.name" :rules="rules.name">
                                        <a-input v-model="form.person.name" style="width:100%;"/>
                                    </a-form-model-item>
                                </a-col>
                               
                                <a-col :span="4" v-if="form.type==1 && brand===21">
                                     <!-- 组织部门 请选择组织部门-->
                                   <a-form-model-item :label="$t('plateNumber.a96')"  prop="person.deptId" :rules="rules.deptId">
                                        <a-tree-select
                                        style="width:100%;"
                                        v-model="form.person.deptId"
                                        :tree-data="carParkDepts"
                                        :replaceFields="{children:'children', title:'name', key:'no', value: 'no' }  "
                                        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                         :placeholder="$t('plateNumber.a97')"
                                        allow-clear
                                        tree-default-expand-all
                                        /> 
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="4" v-if="form.type==1 && brand!==21">
                                    <!-- 组织部门 请选择组织部门-->
                                    <a-form-model-item :label="$t('plateNumber.a96')"  prop="person.deptName" :rules="rules.deptName">
                                        <a-input v-model="form.person.deptName" style="width:100%;"/>
                                    </a-form-model-item>
                                </a-col>

                                 <a-col :span="4">
                                     <!-- 管理代码 -->
                                    <a-form-model-item  :label="$t('plateNumber.a3')" >
                                        <a-input v-model="form.person.managementCode" disabled  style="width:100%;"/>
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="4">
                                    <!-- 手机号码 -->
                                    <a-form-model-item :label="$t('plateNumber.a7')"  prop="person.mobilePhone" :rules="rules.mobilePhone">
                                        <a-input allow-clear v-model="form.person.mobilePhone" style="width:100%;"/>
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="4">
                                    <!-- 电子邮箱 -->
                                    <a-form-model-item  :label="$t('plateNumber.a45')"  prop="person.email" :rules="rules.email" >
                                        <a-input allow-clear v-model="form.person.email" style="width:100%;"/>
                                    </a-form-model-item>
                                </a-col>
                               
                                <a-col :span="4">
                                    <!-- 车主性别 -->
                                    <a-form-model-item :label="$t('plateNumber.a93')">
                                        <a-radio-group v-model="form.person.personGender">
                                            <a-radio :value="0">  {{$t('plateNumber.a94')}} </a-radio>
                                            <a-radio :value="1">  {{$t('plateNumber.a95')}} </a-radio>
                                        </a-radio-group>
                                    </a-form-model-item>
                                </a-col>
                            </a-row>
                        </div>
                    </div>
                    <div>
                        <!-- 车牌信息标题栏和添加车牌功能 -->
                        <div class="plate-title">
                            <div> {{ $t("plateNumber.a48") }} </div>
                            <div> 
                                <a-button type="primary" @click="addPlateNumber">{{ $t("plateNumber.a49") }} </a-button>
                            </div>
                        </div>
                        <!-- 表格1 -->
                        <a-table :columns="columns1" :data-source="form.cars" :pagination="false" :scroll="{ y:240 }" size="small" :rowKey="(record) => record.id" v-if="brand === 21">
                            <!-- 车牌号码 -->
                            <template slot="carPlateNOTitle">
                                <span class="red">*</span>{{$t('plateNumber.a9')}}
                            </template>
                            <template slot="carPlateNO" slot-scope="text, record,index">
                                <a-form-model-item :prop="'cars.' + index + '.carPlateNO'" :rules="rules.carPlateNO">
                                    <a-input allow-clear v-model="record.carPlateNO"/>
                                </a-form-model-item>
                            </template>
                            <!-- 车牌颜色 -->
                            <template slot="carPlateColorTitle">
                                <span class="red">*</span>{{$t('plateNumber.a11')}}
                            </template>
                            <template slot="carPlateColor" slot-scope="text, record, index">
                                <a-form-model-item :prop="'cars.' + index + '.carPlateColor'" :rules="rules.carPlateColor">
                                    <a-select v-model="record.carPlateColor" :title="$t('plateNumber.a11')" >
                                        <a-select-option v-for="(item, index) in carPlateColors" :key="index" :value="item.no" :title="item.name">
                                        {{ item.name }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </template>
                            <!-- 认证模式 -->
                            <template slot="authenticationModeTitle">
                                <span class="red">*</span>{{$t('plateNumber.a18')}}
                            </template>
                            <template slot="authenticationMode" slot-scope="text, record, index">
                                <a-form-model-item :prop="'cars.' + index + '.authenticationMode'" :rules="rules.authenticationMode">
                                    <a-select v-model="record.authenticationMode"  :title="$t('plateNumber.a53')" v-if="brand!==21">
                                        <a-select-option v-for="(item, index) in carAuthModes" :key="index" :value="item.no" :title="item.text">
                                            {{ item.text }}
                                        </a-select-option>
                                    </a-select>

                                    <a-select v-model="record.authenticationMode" :title="$t('plateNumber.a53')"  v-else-if="brand===21" style="width:100%;">
                                        <a-select-option v-for="(item, index) in carAuthModes" :key="index" :value="item.value" :title="item.name">
                                        {{ item.name }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </template>
                            <!-- 开始日期 -->
                            <template slot="activationTimeTitle">
                                <span class="red">*</span>{{$t('plateNumber.a27')}}
                            </template>
                            <template slot="activationTime" slot-scope="text, record, index">
                                <a-form-model-item :prop="'cars.' + index + '.activationTime'" :rules="rules.activationTime">
                                    <a-date-picker v-model="record.activationTime" style="width: 100%"/>
                                </a-form-model-item>
                            </template>
                            <!-- 有效日期 -->
                            <template slot="expirationTermTitle">
                                <span class="red">*</span>{{$t('plateNumber.a28')}}
                            </template>
                            <template slot="expirationTerm" slot-scope="text, record, index">
                                <a-form-model-item :prop="'cars.' + index + '.expirationTerm'" :rules="rules.expirationTerm">
                                    <a-date-picker v-model="record.expirationTerm" style="width: 100%"/>
                                </a-form-model-item>
                            </template>
                            <!-- 通行区域 -->
                            <template slot="areaTitle">
                                <span class="red">*</span>{{$t('plateNumber.a92')}}
                            </template>
                            <template slot="area" slot-scope="text, record, index">
                                <a-form-model-item   v-if="brand === 21" :prop="'cars.' + index + '.area'" :rules="rules.area">
                                    <a-select v-model="record.area"  mode="multiple" >
                                        <a-select-option :value="item.no" v-for="(item,index) in areas" :key="index"> 
                                            {{item.name}}
                                        </a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </template>
                            <!-- 车身颜色 -->
                            <template slot="carColorTitle">
                                {{$t('plateNumber.a51')}}
                            </template>
                            <template slot="carColor" slot-scope="text, record">
                                <a-form-model-item   >
                                    <a-input allow-clear v-model="record.carColor"/>
                                </a-form-model-item>
                            </template>
                            <!-- 汽车品牌 -->
                            <template slot="carBrandTitle">
                                {{$t('plateNumber.a52')}}
                            </template>
                            <template slot="carBrand" slot-scope="text, record">
                                <a-form-model-item   >
                                    <a-input allow-clear v-model="record.carBrand"/>
                                </a-form-model-item>
                            </template>
                            <!-- 操作 -->
                            <template slot="operateTitle">
                                <a-icon type="tool" :title="$t('plateNumber.a60')"/>
                            </template>
                            <template slot="operate" slot-scope="text, record">
                                <a-form-model-item>
                                    <a-button type="primary" @click="() => onDelete(record)"  icon="delete"></a-button>
                                </a-form-model-item>
                            </template>
                            <!-- 表格页脚 -->
                            <template slot="footer">
                                <div class="content-push">
                                    <span>{{$t('plateNumber.a98')}}</span>
                                    <a-checkbox v-model="form.push"> {{$t('plateNumber.a99')}}  </a-checkbox>
                                </div>
                            </template>
                        </a-table>

                        <!-- 表格2 -->
                        <a-table :columns="columns2" :data-source="form.cars" :pagination="false" :scroll="{ y:240 }" size="small" :rowKey="(record) => record.id" v-else >
                            <!-- 车牌号码 -->
                            <template slot="carPlateNOTitle">
                                <span class="red">*</span>{{$t('plateNumber.a9')}}
                            </template>
                            <template slot="carPlateNO" slot-scope="text, record,index">
                                <a-form-model-item :prop="'cars.' + index + '.carPlateNO'" :rules="rules.carPlateNO">
                                    <a-input allow-clear v-model="record.carPlateNO"/>
                                </a-form-model-item>
                            </template>
                            <!-- 车牌颜色 -->
                            <template slot="carPlateColorTitle">
                                <span class="red">*</span>{{$t('plateNumber.a11')}}
                            </template>
                            <template slot="carPlateColor" slot-scope="text, record, index">
                                <a-form-model-item :prop="'cars.' + index + '.carPlateColor'" :rules="rules.carPlateColor">
                                    <a-select v-model="record.carPlateColor" :title="$t('plateNumber.a11')" >
                                        <a-select-option v-for="(item, index) in carPlateColors" :key="index" :value="item.no" :title="item.name">
                                        {{ item.name }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </template>
                            <!-- 认证模式 -->
                            <template slot="authenticationModeTitle">
                                <span class="red">*</span>{{$t('plateNumber.a18')}}
                            </template>
                            <template slot="authenticationMode" slot-scope="text, record, index">
                                <a-form-model-item :prop="'cars.' + index + '.authenticationMode'" :rules="rules.authenticationMode">
                                    <a-select v-model="record.authenticationMode"  :title="$t('plateNumber.a53')" v-if="brand!==21">
                                        <a-select-option v-for="(item, index) in carAuthModes" :key="index" :value="item.no" :title="item.text">
                                            {{ item.text }}
                                        </a-select-option>
                                    </a-select>

                                    <a-select v-model="record.authenticationMode" :title="$t('plateNumber.a53')"  v-else-if="brand===21" style="width:100%;">
                                        <a-select-option v-for="(item, index) in carAuthModes" :key="index" :value="item.value" :title="item.name">
                                        {{ item.name }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </template>
                            <!-- 开始日期 -->
                            <template slot="activationTimeTitle">
                                <span class="red">*</span>{{$t('plateNumber.a27')}}
                            </template>
                            <template slot="activationTime" slot-scope="text, record, index">
                                <a-form-model-item :prop="'cars.' + index + '.activationTime'" :rules="rules.activationTime">
                                    <a-date-picker v-model="record.activationTime" style="width: 100%"/>
                                </a-form-model-item>
                            </template>
                            <!-- 有效日期 -->
                            <template slot="expirationTermTitle">
                                <span class="red">*</span>{{$t('plateNumber.a28')}}
                            </template>
                            <template slot="expirationTerm" slot-scope="text, record, index">
                                <a-form-model-item :prop="'cars.' + index + '.expirationTerm'" :rules="rules.expirationTerm">
                                    <a-date-picker v-model="record.expirationTerm" style="width: 100%"/>
                                </a-form-model-item>
                            </template>
                            <!-- 车身颜色 -->
                            <template slot="carColorTitle">
                                {{$t('plateNumber.a51')}}
                            </template>
                            <template slot="carColor" slot-scope="text, record">
                                <a-form-model-item   >
                                    <a-input allow-clear v-model="record.carColor"/>
                                </a-form-model-item>
                            </template>
                            <!-- 汽车品牌 -->
                            <template slot="carBrandTitle">
                                {{$t('plateNumber.a52')}}
                            </template>
                            <template slot="carBrand" slot-scope="text, record">
                                <a-form-model-item   >
                                    <a-input allow-clear v-model="record.carBrand"/>
                                </a-form-model-item>
                            </template>
                            <!-- 操作 -->
                            <template slot="operateTitle">
                                <a-icon type="tool" :title="$t('plateNumber.a60')"/>
                            </template>
                            <template slot="operate" slot-scope="text, record">
                                <a-form-model-item>
                                    <a-button type="primary" @click="() => onDelete(record)"  icon="delete"></a-button>
                                </a-form-model-item>
                            </template>
                            <!-- 表格页脚 -->
                            <template slot="footer">
                                <div class="content-push">
                                    <span>{{$t('plateNumber.a98')}}</span>
                                    <a-checkbox v-model="form.push"> {{$t('plateNumber.a99')}}  </a-checkbox>
                                </div>
                            </template>
                        </a-table>

                        
                    </div>
                </a-form-model>
            </div>


        </a-modal>
    </div>
</template>
<script>
import { getSitePersonGrTree,getSitePersonList,getSiteCarInfoByPerson,saveSiteCarInfoByPerson} from "../../api/passageway";
import {  deleteCarPlateNumber } from "../../api/door";
import moment from "moment";
const express = /^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[a-zA-Z](([ABCDEFGHK]((?![IO])[a-zA-Z0-9](?![IO]))[0-9]{4})|([0-9]{5}[DF]))|[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1})$/;
const expressMobile = /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/;
export default {
  name: 'personPlateNumberModel',
  props: {
    title: {
      type: String,
      default: '车牌管理',
    },
    idInfo: {
      type: Object,
      default:{value:0,siteId:0,clientId:0,sitegrId:0,type:0},
    },
    areas:{
        type:Array,
        default:[],
    },
    
    carParkDepts:{
        type:Array,
        default:[],
    },
    carAuthModes:{
        type:Array,
        default:[],
    },

    carPlateColors:{
        type:Array,
        default:[],
    },
    sites:{
        type:Array,
        default:[],
    },
    brand:{
        type: Number,
        default:0,
    },
  },
  data() {
       //车牌校验方法
    let validateCarPlateNumber = async (rule, value) => {
        console.log(value)
      if (value === '') {
        return Promise.reject(this.$t("plateNumber.a57"));
      } else if (!express.test(value)) {
        return Promise.reject(this.$t("plateNumber.a68"));
      } else {
        return Promise.resolve();
      }
    };
    //手机号码校验方法
    let validateMobile = async (rule, value) => {
        console.log(value)
      if (value === '') {
        return Promise.reject(this.$t("plateNumber.a56"));
      } else if (!expressMobile.test(value)) {
        return Promise.reject(this.$t("plateNumber.a67"));
      } else {
        return Promise.resolve();
      }
    };
    let normalName = (rule, value, callback) => {
        let regEn = /[`~!@#$%^&*+<>?:",\/;']/im;
 
        if(regEn.test(value)) {
            callback(new Error(this.$t('energy.enedata260')));
        } else {
            callback();
        }
    };
    return {
        data:[],
        loading:false,
        visible:false,
        form: {
            type:0,
            id:0,
            person:{
                id:"",
                name: "",
                mobilePhone: "",
                managementCode: "",
                email: "",
                personGender:1,
                personSiteId:undefined,
                deptId:'',
                deptName:'',
                personGrId:undefined,
                itemId:"",
                personGuId:1,
            },
            cars:[],
            push:true,
        },
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
        tailFormItemLayout: {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 18,
                    offset: 6,
                },
            },
        },
       
        rules: {
            mobilePhone: [
                { required: true, validator: validateMobile, trigger: "blur" }
            ],
            carPlateNO: [
                { required: true, validator: validateCarPlateNumber, trigger: 'blur' }
            ],
            email: [
                {
                    type: 'email',
                    message: this.$t('plateNumber.a107'),//'The input is not valid E-mail!',
                    trigger: "blur",
                }
            ],
            authenticationMode: [
                {
                    required: true,
                    message: () => {
                    return this.$t("plateNumber.a69");
                    },
                    trigger: "change",
                },
            ],
            carPlateColor: [
                {
                    required: true,
                    message: () => {
                    return this.$t("plateNumber.a88");
                    },
                    trigger: "change",
                },
            ],
            name: [
                {
                    required: true,
                    message: () => {
                        return this.$t("plateNumber.a87");
                    },
                    trigger: "blur",
                },
            ],
            deptName: [ { validator: normalName,  trigger: 'blur', }, ],
            id: [
                {
                    required: true,
                    message: () => {
                        return this.$t("plateNumber.a87");
                    },
                    trigger: "change",
                },
            ],
            activationTime: [
            {
                required: true,
                message: () => {
                    return this.$t("plateNumber.a58");
                },
                trigger: "change",
            },
            ],
            expirationTerm: [
            {
                required: true,
                message: () => {
                return this.$t("plateNumber.a58");
                },
                trigger: "change",
            },
            ],
            deptId: [{ required: true, message: this.$t("plateNumber.a97"), trigger: 'change' }],
            area:[
                {
                    type: 'array',
                    required: true,
                    message: this.$t("plateNumber.a100"),//'请选择通行区域',
                    trigger: 'change',
                },
            ],
        }, 
        disallowed:this.$t('energy.setting.reportGr.stop')+'[`~!@#$%^ &amp; *()_+<>?: " { } , . \ / ;  [ \ ] ] ·！#￥（——）：；“”‘、，|《。》？、【】',
        personGrs:[],
        persons:[],
        carParkDeptList:[],
        columns1:[
            {
                dataIndex: 'carPlateNO',
                align: 'center',
                slots: { title: 'carPlateNOTitle' },
                scopedSlots: { customRender: 'carPlateNO' }
            },
            {
                dataIndex: 'carPlateColor',
                align: 'center',
                slots: { title: 'carPlateColorTitle' },
                scopedSlots: { customRender: 'carPlateColor' }
            },
            {
                dataIndex: 'authenticationMode',
                align: 'center',
                slots: { title: 'authenticationModeTitle' },
                scopedSlots: { customRender: 'authenticationMode' }
            },
            {
                dataIndex: 'activationTime',
                align: 'center',
                slots: { title: 'activationTimeTitle' },
                scopedSlots: { customRender: 'activationTime' }
            },
            {
                dataIndex: 'expirationTerm',
                align: 'center',
                slots: { title: 'expirationTermTitle' },
                scopedSlots: { customRender: 'expirationTerm' }
            },
            {
                dataIndex: 'area',
                align: 'center',
                slots: { title: 'areaTitle' },
                scopedSlots: { customRender: 'area' }
            },
            {
                dataIndex: 'carColor',
                align: 'center',
                slots: { title: 'carColorTitle' },
                scopedSlots: { customRender: 'carColor' }
            },
            {
                dataIndex: 'carBrand',
                align: 'center',
                slots: { title: 'carBrandTitle' },
                scopedSlots: { customRender: 'carBrand' }
            },
            {
                dataIndex: 'operate',
                align: 'center',
                width: 60,
                slots: { title: 'operateTitle' },
                scopedSlots: { customRender: 'operate' }
            }
        ],
        columns2:[
        {
                dataIndex: 'carPlateNO',
                align: 'center',
                slots: { title: 'carPlateNOTitle' },
                scopedSlots: { customRender: 'carPlateNO' }
            },
            {
                dataIndex: 'carPlateColor',
                align: 'center',
                slots: { title: 'carPlateColorTitle' },
                scopedSlots: { customRender: 'carPlateColor' }
            },
            {
                dataIndex: 'authenticationMode',
                align: 'center',
                slots: { title: 'authenticationModeTitle' },
                scopedSlots: { customRender: 'authenticationMode' }
            },
            {
                dataIndex: 'activationTime',
                align: 'center',
                slots: { title: 'activationTimeTitle' },
                scopedSlots: { customRender: 'activationTime' }
            },
            {
                dataIndex: 'expirationTerm',
                align: 'center',
                slots: { title: 'expirationTermTitle' },
                scopedSlots: { customRender: 'expirationTerm' }
            },
            {
                dataIndex: 'carColor',
                align: 'center',
                slots: { title: 'carColorTitle' },
                scopedSlots: { customRender: 'carColor' }
            },
            {
                dataIndex: 'carBrand',
                align: 'center',
                slots: { title: 'carBrandTitle' },
                scopedSlots: { customRender: 'carBrand' }
            },
            {
                dataIndex: 'operate',
                align: 'center',
                width: 60,
                slots: { title: 'operateTitle' },
                scopedSlots: { customRender: 'operate' }
            }
        ]
    }
  },
  mounted(){

  },
  watch:{
    "$parent.dialogVisible":{
      handler(val){
          if(val){              
              this.resetForm();
              this.visible = val;
              console.log('idInfo',this.idInfo);
              this.form.type    = this.idInfo.type;
              this.form.id      = this.idInfo.value;
              this.typeChange(this.idInfo.type);
          }
      },
      deep:true,
      immediate:true,
    },
    "visible":{
      handler(val){
          
          if(!val){
              this.$parent.dialogVisible = val;
              this.resetForm();
              this.personGrs=[];
              this.persons=[];
              this.clearInfo(1);
              this.form.cars=[];
          }
      },
      deep:true,
      immediate:true,
    },
  },
  methods: {
    personTypeChange(e){
         
         const {value} = e.target;
         console.log('personTypeChange res',value);
         this.typeChange(value*1);

    },
    typeChange(type){
        let value = this.idInfo.value*1;
        this.form.person.personSiteId = this.idInfo.siteId+'@@site@@1';
        console.log('typeChange res',value);
        if(type===0){
            if(value===0){
                this.loadPersonGrList(this.idInfo);
                this.form.cars = [];
                this.addPlateNumber();
            } else {
                // this.loadPersonGrList(this.idInfo);
                this.loadCarInfoByPerson(type,value);
            }
        }else{
            if(value===0){
                this.form.cars = [];
                this.addPlateNumber();
            } else {
                this.loadCarInfoByPerson(type,value);
            }
        }
         
    },
    loadCarInfoByPerson(type,value){
        let personId = 0;
        let carId = 0;
        let siteId = 0;
        if(type===0){
            let svalues = this.form.person.personSiteId.split("@@");
            siteId = svalues[0];
            personId = value;
        }else{
            carId = value;
            siteId = this.idInfo.siteId;
        }
        let param = {
            action:0,
            clientId:this.idInfo.clientId, 
            sitegrId:this.idInfo.sitegrId,
            siteId:siteId,
            personId:personId,
            personCarId:carId,
        }
        this.form.cars=[];
        this.loading = true;
        getSiteCarInfoByPerson(param)
        .then((res) => {
          console.log('getSitePersonList res',res)
        
          const { data ,errorCode,msg} = res;
          if(errorCode==='00'){
            //   this.form.person = data.person;
            //   this.from.cars
            const{person,cars} = data;
            this.form.person.id         	 = person.id;
            this.form.person.name         	 = person.name;
            this.form.person.mobilePhone     = person.mobilePhone;
            this.form.person.managementCode  = person.managementCode;
            this.form.person.email         	 = person.email;
            this.form.person.personGender	 = person.personGender && person.personGender != null?person.personGender:1;   
            this.form.person.personSiteId    = person.personSiteId;
            this.form.person.deptId          = person.deptId;
            this.form.person.deptName        = person.deptId;
            this.form.person.personGrId      = person.parentId;
            this.form.person.itemId          = person.itemId;
            this.form.person.personGuId      = person.personGuId;
            if(cars.length>0){
                cars.forEach(element => {
                    element.key = element.id;
                    element.activationTime = new moment(element.activationTime),
                    element.expirationTerm = new moment(element.expirationTerm);
                    if(this.brand!==21){
                        element.authenticationMode = element.authenticationMode+"";
                        // element.carPlateColor = element.carPlateColor*1;
                    }
                    this.form.cars.push(element) ;
                });
            } else {
                if(this.form.cars.length==0){
                    this.addPlateNumber();
                }
            }
            if(this.form.type===0){
                let cvalues = this.form.person.personSiteId.split("@@");
                let csiteId = cvalues[0];
                let param = {
                    type: 0,
                    value: 0,
                    clientId: this.idInfo.clientId,
                    sitegrId: 0,
                    siteId: csiteId
                }
                this.loadPersonGrList(param);
                let param2 = {
                    action:0,
                    clientId:this.idInfo.clientId, 
                    sitegrId:0,
                    siteId:csiteId,
                    personGrId:person.parentId,
                }
                this.loadPersonList(param2);
            }
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    personSelectChange(value,names,obj){
    //    console.log('personSelectChange',value,names,obj.triggerNode.dataRef);
        // let values = value.split(",");
        if(obj.triggerNode && obj.triggerNode.dataRef){

            let person = obj.triggerNode.dataRef;
                    
            this.form.person.name         	 = person.name;
            this.form.person.mobilePhone     = person.mobilePhone;
            this.form.person.managementCode  = person.managementCode;
            this.form.person.email         	 = person.email;
            this.form.person.itemId          = person.itemId;
            this.form.person.personGender	 = person.personGender && person.personGender != null?person.personGender:1;        
            this.loadCarInfoByPerson(this.idInfo.type,value);

        } else {
            this.clearInfo(2);
        }

        

    },
    loadPersonList(param){
        getSitePersonList(param)
        .then((res) => {
          console.log('getSitePersonList res',res)
        
          const { data ,errorCode,msg} = res;
          if(errorCode==='00'){
              this.persons = data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    personGrSelectChange(value,names,obj){
        // console.log('personGrSelectChange',value,names,obj.triggerNode.dataRef);
        // let values = value.split(",");
        if(obj.triggerNode && obj.triggerNode.dataRef){
            let df = obj.triggerNode.dataRef;
            let svalues = this.form.person.personSiteId.split("@@");
            let param = {
                action:0,
                clientId:this.idInfo.clientId, 
                sitegrId:this.idInfo.sitegrId,
                siteId:svalues[0],
                personGrId:value,
            }
            this.form.person.deptId = df.itemId;
            this.clearInfo(2);
            this.loadPersonList(param);
        }else{
            this.persons=[];
            this.clearInfo(2);
        }
    },
    clearInfo(action){
        if(action===1){
            this.form.person.deptId = undefined;
            this.form.person.personGrId = undefined;
            this.personGrs=[];
        }

        this.form.person.id=undefined;
        this.form.person.name         	 = undefined;
        this.form.person.mobilePhone     = undefined;
        this.form.person.managementCode  = undefined;
        this.form.person.email         	 = undefined;
        this.form.person.personGender	 = undefined;  
        this.form.person.itemId     	 = undefined;  
        this.form.person.deptName        = undefined;
    },
    loadPersonGrList(param){
        getSitePersonGrTree(param)
        .then((res) => {
          console.log('getSitePersonGrTree res',res)
        
          const { data ,errorCode,msg} = res;
          if(errorCode==='00'){
              this.personGrs = data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    siteSelectChange(value,b,obj){
        console.log('siteSelectChange',value,b,obj);
        if(obj.triggerNode && obj.triggerNode.dataRef){
            let values = value.split("@@");
            let param = {
                action:0,
                clientId:this.idInfo.clientId, 
                sitegrId:this.idInfo.sitegrId,
                siteId:values[0],
            }
            this.clearInfo(1);
            this.loadPersonGrList(param);
        } else {
            this.clearInfo(1); 
        }
    },
    addPlateNumber(){
        console.log(this.form.cars)
         this.form.cars.push({
            id:0,
            carPlateNO: "",
            carPlateColor: undefined,
            authenticationMode: undefined,
            activationTime: new moment(),
            expirationTerm: new moment(),
            area:[],
            carColor: "",
            carBrand: "",
            key:Date.now(),
        });
    },
    onDelete(item){
        this.$confirm({
            title: this.$t('plateNumber.a86'),
            centered: true,
            onOk: () => {
                if(item.id>0){
                    this.delete(item.id);
                } else {

                }
                let index = this.form.cars.indexOf(item);
                if (index !== -1) {
                    this.form.cars.splice(index, 1);
                }
                
            },
        });
        
    },
    carPlateNOChange(e,record,index){
       
        const {value} = e.target;
        console.log(value,value.length);
        let bol = express.test(value);
        if(bol){
            if(value.length==7){
                this.form.carPlateColor = '3';
            }else if(value.length==8){
                this.form.carPlateColor = '5';
            }
        }
    },
   
    onSubmit() {
        this.$refs.ruleForm.validate(valid => {
            if (valid) {
                    this.$confirm({
                    title: this.$t('plateNumber.a108'),//'确认要保存所设置的车牌信息吗？',
                    centered: true,
                    onOk: () => {
                        this.sava();
                    },
                });
            } else {
                console.log('error submit!!');
                return false;
            }
        });
    },
    resetForm() {
        if(this.$refs.ruleForm){
            try {
                this.$refs.ruleForm.resetFields();
            } catch (error) {
                console.log('error resetForm! ' + error);
            }
        }
    },
    // 车牌删除接口
    delete(id){
        let ids = [];
        ids.push(id);
        const data = {
            action: 0,
            clientId: this.idInfo.clientId,
            sitegrId: this.idInfo.sitegrId,
            siteId: this.idInfo.siteId,
            ids: ids,
        };
        this.loading = true;
        deleteCarPlateNumber(data)
        .then((res) => {
            this.loading = false;
            const { errorCode, msg } = res;
            if (errorCode == "00" || errorCode == "05") {
                this.$message.success(msg);
                this.$parent.searchPersonCarListData();
                
            } else {
                this.$message.error(msg);
            }
            
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
  
    },
    sava(){
        console.log('sava',this.form);
        this.form.cars.forEach(element => {
            element.activationTime = element.activationTime.format('YYYY-MM-DD');
            element.expirationTerm = element.expirationTerm.format('YYYY-MM-DD');
        });
        const data = {
            action: 0,
            clientId: this.idInfo.clientId,
            sitegrId: this.idInfo.sitegrId,
            siteId  : this.idInfo.siteId,
            data    : this.form,
        }
        this.loading = true;
        console.log('saveSiteCarInfoByPerson',data);
        saveSiteCarInfoByPerson(data)
        .then((res) => {
            this.loading = false;
            const { errorCode, msg } = res;
            if (errorCode == "00" || errorCode == "03") {
                this.$message.success(msg);
                this.visible = false;
                this.$parent.searchPersonCarListData();
            } else {
                this.$message.error(msg);
            }
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
  
    },
  },
};
</script>
<style scoped>

div /deep/.ant-modal-header {
  background-color: #7682ce;
}
.main{
    height: 600px;
}
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 42px;
    padding: 0px 200px 10px 10px;
}
.personnel-info{
    margin-bottom: 15px;
}
.plate-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    font-size: 16px;
    background-color: #e8e8e8;
}
.plate-content{
    border:1px solid #e8e8e8;
    padding: 10px;
}
.content-tool{
    padding: 10px 5px;
}
.content-area{
    padding: 10px 5px;
}
.content-push{
    /* border-top:1px solid #e8e8e8; */
    text-align: right;
    /* padding: 10px 5px; */
}
.content-push span{
    padding-right: 10px;
}
.area-title{
    padding: 5px;
    background-color: #e8e8e8;
}
div /deep/ .ant-modal-body {
    padding:10px;
}
.red{
    color: #ff0000;
}
</style>